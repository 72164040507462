<template>
  <b-card>
    <FormView
      :data="$store.getters['subscription/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'
import { subscriptionViewPayment } from '@/list/payment-method'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'subscriber.nickname',
          label: 'Subscriber',
          type: 'profile',
          image: 'subscriber.avatar',
          url: {
            callback(data) {
              return data.subscriber ? `/user/${data.subscriber.id}` : null
            },
          },
        },
        {
          key: 'subscriber.profile.countryCode',
          label: 'Subscriber country',
          type: 'country',
          visibility: ['view'],
        },
        {
          key: 'subscriber.email',
          label: 'Subscriber email',
          type: 'email',
          visibility: ['view'],
        },
        {
          key: 'creator.nickname',
          label: 'Creator',
          type: 'profile',
          image: 'creator.avatar',
          sortable: false,
          url: {
            callback(data) {
              return data.creator ? `/user/${data.creator.id}` : null
            },
          },
        },
        {
          key: 'creator.profile.countryCode',
          label: 'Creator country',
          type: 'country',
          visibility: ['view'],
        },
        {
          key: 'creator.email',
          label: 'Creator email',
          type: 'email',
          visibility: ['view'],
        },
        subscriptionViewPayment,
        {
          key: 'type',
          label: 'Type',
          type: 'dot',
          map: {
            'one-time': 'success',
            'auto renewal': 'warning',
          },
        },
        {
          key: 'active_status',
          label: 'Status',
          type: 'badge',
          map: {
            active: 'success',
            inactive: 'danger',
          },
        },
        // all value is in usd
        {
          key: 'amount',
          label: 'Amount',
          prefix: '$',
          suffix: ' USD',
          visibility: ['view'],
        },
        {
          key: 'subscriptionStart',
          label: 'Started at',
          type: 'datetime',
          visibility: ['view'],
        },
        {
          key: 'subscriptionEnd',
          label: 'Ended at',
          type: 'datetime',
          visibility: ['view'],
        },
        {
          key: 'payment_status',
          label: 'Payment status',
          type: 'dot',
          map: {
            completed: 'success',
          },
          visibility: ['view'],
        },
        {
          key: 'cancelled_status',
          label: 'Cancelled',
          type: 'dot',
          map: {
            cancelled: 'danger',
          },
          visibility: ['view'],
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('subscription/getDetail', id)
    },
  },
}
</script>

  <style scoped>

  </style>
